import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['rentalTypeSelect']
  change(event) {
    const listingType = event.target.selectedOptions[0].value
    const target = this.rentalTypeSelectTarget.id
    get(`/comparable_properties/rental_types?listing_type=${listingType}&target=${target}`, {
      responseKind: 'turbo-stream'
    })
  }
}
