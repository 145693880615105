import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['dropdown']
  static values = { create: Boolean }

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, this.baseSettings)
    this.element.tomSelect = this.tomSelect
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  get baseSettings() {
    return {
      create: this.createValue,
      maxOptions: null,
      maxItems: this.dropdownTarget.multiple ? null : 1,
      plugins: this.dropdownTarget.multiple ? ['remove_button'] : []
    }
  }
}
