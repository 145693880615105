import './index.scss'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'form']

  attach() {
    // TODO: Change to forEach.
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.fileInputTarget.files.length; i++) {
      const template = document.getElementById('attachment-template')
      const fragment = template.content.cloneNode(true)
      const filesInput = fragment.querySelector('input.files')
      filesInput.files = this.fileInputTarget.files
      const nameSpan = fragment.querySelector('span.name')
      nameSpan.innerHTML = this.fileInputTarget.files[i].name
      const list = document.getElementById('uploaded-files-list')
      list.appendChild(fragment)
    }
  }

  close() {
    this.element.remove()
  }

  save() {
    this.fileInputTarget.remove()
  }
}
