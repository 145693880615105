import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  remove(event) {
    event.preventDefault()
    this.fieldTarget.value = 1
    this.fieldTarget.form.requestSubmit()
  }
}
