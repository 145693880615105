import { Controller } from '@hotwired/stimulus'

import Reveal from 'reveal.js'

export default class extends Controller {
  connect() {
    const deck = new Reveal({
      width: 792, // This precise pixel value is based off Figma's 8.5x11" frame size and must not be dynamic.
      height: 612, // Same here.
      margin: 0,
      maxScale: 3.0,
      transition: 'none',
      backgroundTransition: 'none'
    })
    deck.initialize()
  }
}
