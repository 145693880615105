import '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import './controllers'
import './initializers/frame_missing_handler'
import './initializers/honeybadger'
// eslint-disable-next-line import/no-unresolved
import '@shoelace-style/shoelace' // TODO: Why can't this import be found by eslint/import?
import 'trix'
import '@rails/actiontext'

const application = Application.start()
const components = require.context('../components', true, /\.js$/)

// functions from https://github.com/hotwired/stimulus-webpack-helpers/blob/master/src/index.ts
// this function was changed to work with components with paths such as "./header/index.js"
function identifierForContextKey(key) {
  const logicalName = (key.match(/^(?:\.\/)?(.+)(\/index.js)$/) || [])[1]
  if (logicalName) {
    return logicalName.replace(/_/g, '-').replace(/\//g, '--')
  }
}

function definitionForModuleAndIdentifier(module, identifier) {
  const controllerConstructor = module.default
  if (typeof controllerConstructor === 'function') {
    return { identifier, controllerConstructor }
  }
}

function definitionForModuleWithContextAndKey(context, key) {
  const identifier = identifierForContextKey(key)
  if (identifier) {
    return definitionForModuleAndIdentifier(context(key), identifier)
  }
}

function definitionsFromContext(context) {
  return context
    .keys()
    .map((key) => definitionForModuleWithContextAndKey(context, key))
    .filter((value) => value)
}

export function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute('content')
}

application.load(definitionsFromContext(components))
window.Stimulus = application

export { application }
