import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  static values = {
    dirtyClass: String,
    dirtyIcon: String,
    dirtyMessage: String
  }

  markDirty() {
    if (this.hasDirtyMessageValue) {
      this.contentTarget.textContent = this.dirtyMessageValue
    }

    this._clearIcon()
    if (this.hasDirtyIconValue) {
      this.contentTarget.insertAdjacentHTML('beforebegin', this.dirtyIconValue)
    }

    if (this.hasDirtyClassValue) {
      this.element.classList.add(this.dirtyClassValue)
    }

    this.isDirty = true
  }

  markClean() {
    this._clearIcon()

    if (this.hasDirtyClassValue) {
      this.element.classList.remove(this.dirtyClassValue)
    }

    this.contentTarget.textContent = ''
    this.isDirty = false
  }

  _clearIcon() {
    const icon = this.contentTarget.parentElement.querySelector('.icon')
    if (icon) icon.remove()
  }
}
