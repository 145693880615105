import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// TODO: Figure out default export, or remove this TODO and keep the rule disabled for this line.
// eslint-disable-next-line import/prefer-default-export
export { application }
