import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'toggle']

  useSame() {
    if (!this.hasToggleTarget || !this.hasFieldsetTarget) return

    if (this.toggleTarget.checked) {
      this.fieldsetTarget.disabled = true
      this.fieldsetTarget.style.display = 'none'
    } else {
      this.fieldsetTarget.disabled = false
      this.fieldsetTarget.style.display = 'block'
    }
  }
}
