import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  static values = {
    url: String,
    turboFrame: {
      type: String,
      default: 'modal'
    }
  }

  change(_e) {
    const query = new URLSearchParams(this.fieldTargets.map((field) => [field.name, field.value]))
    window.Turbo.visit(`${this.urlValue}?${query.toString()}`, {
      frame: this.turboFrameValue
    })
  }
}
