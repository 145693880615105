import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  connect() {
    this.fieldTargets.forEach((field) => {
      field.setAttribute('type', 'password')
    })
  }

  toggle(e) {
    const value = e.target.checked ? 'text' : 'password'
    this.fieldTargets.forEach((field) => {
      field.setAttribute('type', value)
    })
  }
}
