import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'toggle']
  static values = { last: String }

  usePrevious() {
    this.inputTarget.value = this.lastValue
  }

  manualUpdate() {
    // If there is a checkbox, uncheck it. Otherwise, we may be in a
    // month which had no previous data, so the checkbox doesn't exist.
    if (this.hasToggleTarget) {
      this.toggleTarget.checked = false
    }
  }
}
