import './index.scss'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['description', 'purpose']

  connect() {
    this.change({ target: this.purposeTarget })
  }

  remove(event) {
    event.preventDefault()
    this.element.remove()
  }

  change(event) {
    if (!this.hasDescriptionTarget) {
      return
    }

    const fieldType = event.target.value === 'Other' ? 'text' : 'hidden'
    this.descriptionTarget.setAttribute('type', fieldType)
  }
}
