import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  copy() {
    navigator.clipboard.writeText(this.element.innerText)
    this.flashNotice('Copied!')
  }

  flashNotice(text) {
    const textWrapper = document.createElement('div')
    textWrapper.classList.add('alert__title')
    textWrapper.innerText = text

    const flashContainer = document.createElement('div')
    flashContainer.classList.add('alert--flash', 'alert-notice')
    flashContainer.appendChild(textWrapper)

    document.getElementById('flash').replaceChildren(flashContainer)
  }
}
