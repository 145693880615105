import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'businessOnly',
    'expensesPersonal',
    'homeOfficeSqFt',
    'mortgageInterest',
    'propertyTaxes',
    'insurance',
    'pmi',
    'physical'
  ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (!this.hasPhysicalTarget || !this.hasBusinessOnlyTarget || !this.hasExpensesPersonalTarget) return

    if (this.physicalTarget.checked) {
      if (this.homeOfficeSqFtTarget.value > 0) {
        this.businessOnlyTarget.style.display = 'block'
      } else {
        this.businessOnlyTarget.style.display = 'none'
      }
      if (
        this.mortgageInterestTarget.value > 0 ||
        this.propertyTaxesTarget.value > 0 ||
        this.insuranceTarget.value > 0 ||
        this.pmiTarget.value > 0
      ) {
        this.expensesPersonalTarget.style.display = 'block'
      } else {
        this.expensesPersonalTarget.style.display = 'none'
      }
    } else {
      this.businessOnlyTarget.style.display = 'none'
      this.expensesPersonalTarget.style.display = 'none'
    }
  }
}
