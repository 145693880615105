import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.element.type = 'submit'
    this.element.form.requestSubmit(this.element)
  }
}
