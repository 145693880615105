import './index.scss'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expense', 'expenseInput', 'dialog', 'businessInput', 'businessMiles', 'totalInput', 'totalMiles']

  add(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
  }

  cancel(event) {
    event.preventDefault()
    this.dialogTarget.close()
  }

  done(event) {
    event.preventDefault()
    const businessMiles = this.safeParseInt(this.businessInputTarget.value)
    this.businessMilesTarget.innerHTML = this.pluralize(businessMiles, 'mile')
    const totalMiles = this.safeParseInt(this.totalInputTarget.value)
    this.totalMilesTarget.innerHTML = this.pluralize(totalMiles, 'mile')
    let expenses = 0
    this.expenseInputTargets.forEach((el) => {
      if (this.isNumber(el.value)) {
        expenses += parseFloat(el.value)
      }
    })
    if (this.hasExpenseTarget) {
      this.expenseTarget.innerHTML = `$${expenses.toFixed(2)}`
    }
    this.dialogTarget.close()
  }

  pluralize(count, noun, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`
  }

  isNumber(str) {
    return /^\d+(\.\d+)?$/i.test(str)
  }

  safeParseInt(str) {
    const result = parseInt(str, 10)
    if (Number.isNaN(result)) {
      return 0
    }
    return result
  }
}
