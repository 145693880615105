import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['click']

  click(e) {
    // Ignore click if it comes from a different link, button, or input
    if (!this.hasClickTarget || e.target.closest('a, button, input, label')) {
      return
    }

    if (this.data.get('turbolinks') === 'false') {
      window.location = this.clickTarget.href
    } else {
      window.Turbo.visit(this.clickTarget.href, {
        frame: this.clickTarget.dataset.turboFrame
      })
    }
  }
}
