import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dialog']

  add(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
  }

  cancel(event) {
    event.preventDefault()
    this.dialogTarget.close()
  }

  close() {
    this.dialogTarget.close()
  }
}
