/**
 * Stimulus controller to handle selection of an item in a dropdown (select),
 * where each item represents an individual page to which the app can be navigated/directed.
 */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  change(event) {
    // Prevent the default form submission behavior
    event.preventDefault()

    // Use Turbo to navigate to the selected option's value
    window.Turbo.visit(event.target.value)
  }
}
