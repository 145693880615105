import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const cells = this.element.getElementsByTagName('td')

    // TODO: Move to forEach.
    // eslint-disable-next-line no-restricted-syntax
    for (const element of cells) {
      element.addEventListener('click', (event) => {
        this.getInput(event.target)?.focus()
      })
    }
  }

  getInput(cell) {
    return cell.getElementsByTagName('input')[0] ?? cell.getElementsByTagName('select')[0]
  }
}
