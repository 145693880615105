import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'select']
  static values = {
    option: String
  }

  connect() {
    this.toggle()
  }

  toggle() {
    this.fieldsetTarget.style.display = 'none'

    const selectedIndex = this.selectTarget.selectedIndex;
    if (selectedIndex >= 0 && this.selectTarget.options[selectedIndex].value === this.optionValue) {
      this.fieldsetTarget.style.display = 'grid'
    }
  }
}
