import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.lastValid = this.element.value
  }

  allowOnlyNumbers(e) {
    if ((e.target.value && /^\d*\.?\d*$/.test(e.target.value)) || e.target.value === '') {
      this.lastValid = e.target.value
    } else {
      e.target.value = this.lastValid
    }
  }
}
