import { Controller } from '@hotwired/stimulus'
import Dropzone from 'dropzone'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static values = {
    url: String,
    clickable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false }
  }

  static targets = ['input']

  connect() {
    this.dropzone = new Dropzone(this.element, {
      autoQueue: false,
      url: this.urlValue,
      clickable: this.clickableValue,
      uploadMultiple: this.multipleValue,
      previewsContainer: false,
      addedfiles: this.addedfiles.bind(this)
    })
  }

  disconnect() {
    this.dropzone.destroy()
  }

  activate() {}

  deactivate() {}

  addedfiles(files) {
    Array.from(files).forEach(async (file) => {
      const uploader = new DirectUpload(file, this.urlValue)

      uploader.create((error, blob) => {
        if (error) {
          // Handle the error
        } else {
          this.inputTarget.setAttribute('value', blob.signed_id)
          this.element.requestSubmit()
        }
      })

      this.deactivate()
    })
  }
}
