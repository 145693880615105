import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.element.value === '') {
      const now = new Date()
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
      this.element.value = now.toISOString().slice(0, 16)
    }
  }
}
