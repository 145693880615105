import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'cell', 'checkbox', 'table' ]

  connect() {
  }

  markComplete({ detail }) {
    let field, value
    const month = detail.month
    this.tableTargets.forEach(table => {
      table.querySelectorAll(`td[data-month='${month}']`).forEach(td => {
        field = td.querySelector(`input[type='text']`)
        if (field) {
          value = field.value
          if (value.length === 0) {
            field.value = 0
          }
        } else {
          field = td.querySelector('select')
          if (field) {
            value = field.value
            if (value.length === 0) {
              const options = field.options

              for (let i = 0; i < options.length; i += 1) {
                if (options[i].value === 'No') {
                  options[i].selected = true
                  break
                }
              }
            }
          } else {
            field = td.querySelector('template')
            if (field) {
              const hidden = td.querySelectorAll('input[type="hidden"]')
              hidden.forEach(el => el.remove())
              const fragment = field.content.cloneNode(true)
              td.appendChild(fragment)
            }
          }
        }
      })
    })
  }
}
